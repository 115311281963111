import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "../assets/show.scoped.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getMatchDetailsCricketAPI,
  getMyBetsCricketAPI,
  placeCricketBetAPI,
  placeFancyCricketBetAPI,
} from "../service/cricket";
import { applyFancyFor, getProfit } from "../utils/constants";
import {
  getButtonValuesAPI,
  getLiveStreamingUrlAPI,
  getSportsScoreAPI,
} from "../service/AuthService";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
  cricketMatchOddsPL,
} from "../utils/profitLoss";
import toast from "react-hot-toast";

import { useQuery } from "react-query";
// import { Spinner } from "@chakra-ui/react";
import Modal from "react-bootstrap/Modal";

import axios from "axios";
import moment from "moment";

export default function BetPlay() {
  const [fancyPlusMinus, setFancyPlusMinus] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [titleInfo, setInfoTitle] = React.useState("");
  const handleOpenInfoModal = (title) => {
    setOpen(true);
    setInfoTitle(title);
  };
  const [BetModalopen, setBetModalOpen] = React.useState(false);
  const handleCloseInfoModal = () => setOpen(false);
  const [showDiv, setShowDiv] = useState(false);
const cricketData = useSelector((state) =>
    state?.gameData?.cricketGames
      ?.filter((item) => {
        
          return item?.inPlay;
       
      })
      .map((item) => {
        return {
          ...item,
          sport: "cricket",
        };
      })
  );
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

  
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    
  };
  const betPlaceRefMobile = useRef(null);
  const [showTv, setShowTv] = useState(false);
  const [fallOfWicketsOdds, setFallOfWicketsOdds] = useState([]);
  const [numericOdds, setNumericOdds] = useState([]);
  const [firstWicketOdds, setFirstWicketOdds] = useState([]);
  const [otherOdds, setOtherOdds] = useState([]);
  const [boundariesOdds, setBoundariesOdds] = useState([]);
  const [tvInnerHtml, setTvInnerHtml] = useState("");
  const [showTimer, setShowTimer] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(5);
  const [channelId, setChannelId] = useState(null);
  const handleBetModalClose = () => {
    setBetModalOpen(false);
  };

  const handleDefaultValueClick = (value, toAppend = false) => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        ...selectedEventForBet,
        amount: toAppend
          ? String(
              selectedEventForBet?.amount ? selectedEventForBet.amount : ""
            ) + String(value)
          : value,
        profit: getProfit(value, game, selectedEventForBet).profit,
        loss: getProfit(value, game, selectedEventForBet).loss,
      },
    });
  };
  useEffect(() => {
    //handle betplace outside click
    const handleClickOutside = (event) => {
      if (
        betPlaceRefMobile.current &&
        !betPlaceRefMobile.current.contains(event.target)
      ) {
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [betPlaceRefMobile]);
  const [oddQuery, setOddQuery] = useState();
  const scoreData = useQuery("scoreData", () => getSportsScoreAPI(id), {
    refetchInterval: 4000,
  });
  var { id, game } = useParams();
  const location = useLocation();
  if (!id || !game) {
    var path = location.pathname.split("/");
    id = path[path.length - 1];
    game = path[path.length - 2];
  }
  const [gameEvent, setGameEvent] = useState({});
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );

  const dispatch = useDispatch();
  const [userBets, setUserBets] = useState();

  var gameSettings_ = useSelector((state) => state.gameData[game + "Settings"]);
  var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  if (gameSettings && gameSettings[id]) {
    gameSettings = gameSettings[id];
  }
  const [showScore, setShowScore] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const userData_ = useSelector((state) => state.account.userData);
  const userData = useMemo(() => userData_, [userData_]);

  useEffect(() => {
    let interval;
    getOdds();
    interval = setInterval(() => getOdds(), 1000);

    return () => clearInterval(interval);
  }, [id]);

  const getGameEventDetails = async () => {
    const { response } = await getCricketEventDetailsAPI(id);
    setGameEvent(response);
  };
const getCricketMatches = async () => {
  try {
    const { response, code } = await getAllMatchesOddsCricketAPI();

    if (code == 200) {
      let counts = await getBetCount();
      response.map((item) => {
        let countData =
          counts?.betCount[
            Object.keys(counts.betCount).find(
              (count) => count == item?.cricketId
            )
          ];

        item.matchCount = countData?.matchCount;
        item.fancyCount = countData?.fancyCount;
        return item;
      });

      dispatch({
        type: "gameData/setCricketGames",
        payload: response,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
useEffect(() => {
  let interval;
  getCricketMatches();

  getCricketMatches();
  interval = setInterval(() => {
    getCricketMatches();
  }, 5000);

  return () => {
    clearInterval(interval);
  };
}, []);
useEffect(() => {
  getGameEventDetails();
}, [id]);
useEffect(() => {
  dispatch({
    type: "gameData/setSelectedEventForBet",
    payload: null,
  });
}, [game, id]);

const setSelectedEventForBet = (data) => {
  if (data.isBookmaker) {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        ...data,
        eventId: id,
        amount: 0,
        profit: getProfit(0, game, data).profit,
        loss: getProfit(0, game, data).loss,
      },
    });
  } else {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        ...data,
        eventId: id,
        amount: 0,
        profit: getProfit(0, game, data).profit,
        loss: getProfit(0, game, data).loss,
      },
    });
  }
};

const getCricketMatchDetails = useCallback(async () => {
  const { response } = await getMatchDetailsCricketAPI(id);
  dispatch({
    type: "gameData/setMatchSettings",
    payload: {
      game,
      settings: response,
      eventId: id,
    },
  });
}, []);

useEffect(() => {
  if (userData.token) {
    getCricketMatchDetails();
    let interval = setInterval(() => {
      getCricketMatchDetails();
    }, 10000);
    return () => clearInterval(interval);
  }
}, []);
const fancySetBet = (data, type, market, gameType) => {
  let matchData = oddQuery?.data?.response?.fancyOdds?.find(
    (item) => item?.gameType?.toLocaleLowerCase() == gameType
  );

  if (!matchData) return;

  let selectedEventForBet = {
    eventId: id,
    amount: 0,
    marketId: data?.marketId,
    marketType: market,
    marketName: matchData?.marketName,
    type: type?.startsWith("lay") ? "lay" : "back",
    isBookmaker: false,
    selectedRate: data[type?.startsWith("lay") ? "laySize1" : "backSize1"],
    selectedOdd: data[type],
    selectedId: data.selectionId,
    profit: getProfit(0, game, data).profit,
    loss: getProfit(0, game, data).loss,
    amount: null,
    runnerName: data.runnerName,
    isYesAndNo: true,
    isFancy: true,
    gameType: gameType,
  };
  if (selectedEventForBet?.selectedOdd == 0) {
    return;
  }
  setBetModalOpen(true);
  setShowTimer(true);
  setTimerSeconds(5);
  dispatch({
    type: "gameData/setSelectedEventForBet",
    payload: selectedEventForBet,
  });
};
const bookmakerSetBet = (data, type, market, gameType) => {
  let matchData = oddQuery?.data?.response?.bookMakerOdds[0]?.bm1;
  if (!matchData) return;

  let selectedEventForBet = {
    eventId: id,
    amount: 0,
    marketId: matchData?.marketId,
    marketType: market,
    marketName: matchData?.marketName,
    type: type?.startsWith("lay") ? "lay" : "back",
    isBookmaker: true,
    selectedRate: data[type?.startsWith("lay") ? "laySize1" : "backSize1"],
    selectedOdd: data[type],
    selectedId: data.selectionId,
    profit: getProfit(0, game, data).profit,
    loss: getProfit(0, game, data).loss,
    amount: null,
    runnerName: data.runnerName,
    isYesAndNo: false,
  };
  if (selectedEventForBet?.selectedOdd == 0) {
    return;
  }
  setBetModalOpen(true);
  setShowTimer(true);
  setTimerSeconds(5);
  dispatch({
    type: "gameData/setSelectedEventForBet",
    payload: selectedEventForBet,
  });
};

const myBets = useCallback(async () => {
  try {
    const { response, code, extra } = await getMyBetsCricketAPI(
      id,
      userData.token
    );
    if (code == 200) {
      setUserBets(response);
      setFancyPlusMinus(extra?.fancyPlusMinus);
    }
  } catch (err) {
    setUserBets([]);
  }
}, []);
const getOdds = async () => {
  const { response, code } = await getCricketOddsByMatchIdAPI(id);
  if (code == 200) {
    setOddQuery({
      data: {
        response: {
          ...response,
        },
      },
    });
    let normalFancy = response?.fancyOdds?.find(
      (item) => item?.gameType?.toLocaleLowerCase() == "normal"
    );

    let numericOdds_ = [];
    let fallOfWicketsOdds_ = [];
    let firstWicketOdds_ = [];
    let otherOdds_ = [];
    let boundariesOdds_ = [];
    let runsOdds_ = [];

    for (let i = 0; i < normalFancy?.oddDetailsDTOS?.length; i++) {
      if (
        checkIfStringIsNumber(
          normalFancy?.oddDetailsDTOS[i]?.runnerName?.split(" ")[0]
        ) ||
        normalFancy?.oddDetailsDTOS[i]?.runnerName
          ?.toLowerCase()
          ?.startsWith("fall of ") ||
        normalFancy?.oddDetailsDTOS[i]?.runnerName
          ?.toLowerCase()
          ?.includes("wkt runs")
      ) {
        if (
          checkIfStringIsNumber(
            normalFancy?.oddDetailsDTOS[i]?.runnerName?.split(" ")[0]
          )
        ) {
          numericOdds_.push(normalFancy?.oddDetailsDTOS[i]);
        }
        if (
          normalFancy?.oddDetailsDTOS[i]?.runnerName
            ?.toLowerCase()
            ?.startsWith("fall of ")
        ) {
          fallOfWicketsOdds_.push(normalFancy?.oddDetailsDTOS[i]);
        }
        if (
          normalFancy?.oddDetailsDTOS[i]?.runnerName
            ?.toLowerCase()
            ?.includes("wkt runs")
        ) {
          firstWicketOdds_.push(normalFancy?.oddDetailsDTOS[i]);
        }
      } else {
        otherOdds_.push(normalFancy?.oddDetailsDTOS[i]);
      }
    }
    numericOdds_ = numericOdds_?.sort((a, b) => {
      return a?.runnerName?.split(" ")[0] - b?.runnerName?.split(" ")[0];
    });
    let matchOverOdds_ = [];
    let partnershipOdds_ = [];
    let totalMatchRuns_ = [];

    for (let i = 0; i < otherOdds_.length; i++) {
      if (otherOdds_[i]?.runnerName?.toLowerCase()?.includes("boundaries")) {
        boundariesOdds_.push(otherOdds_[i]);
        otherOdds_.splice(i, 1);
      }
      if (otherOdds_[i]?.runnerName?.toLowerCase()?.includes("wkt pship")) {
        partnershipOdds_.push(otherOdds_[i]);
        otherOdds_.splice(i, 1);
      }
      if (
        otherOdds_[i]?.runnerName?.toLowerCase()?.includes("match 1st over run")
      ) {
        matchOverOdds_.push(otherOdds_[i]);
        otherOdds_.splice(i, 1);
      }
      if (
        otherOdds_[i]?.runnerName?.toLowerCase()?.includes("total match runs")
      ) {
        totalMatchRuns_.push(otherOdds_[i]);
        otherOdds_.splice(i, 1);
      }
    }
    setNumericOdds([...matchOverOdds_, ...numericOdds_]);
    setFallOfWicketsOdds(fallOfWicketsOdds_);
    setFirstWicketOdds(
      firstWicketOdds_.sort((a, b) => {
        return a?.runnerName?.split(" ")[1] - b?.runnerName?.split(" ")[1];
      })
    );
    setBoundariesOdds([...boundariesOdds_, ...totalMatchRuns_]);
    setOtherOdds([...otherOdds_, ...partnershipOdds_]);
  }
};
const checkIfStringIsNumber = (str) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};
useEffect(() => {
  myBets();
  let interval = setInterval(() => {
    myBets();
  }, 5000);
  return () => clearInterval(interval);
}, [game, id]);

const placeBet = async () => {
  setIsLoading(true);
  let delay = 1500;
  let selectedAmount_ = selectedEventForBet?.amount;
  if (selectedAmount_ <= 0) {
    toast.error("Please enter a Valid Amount");
    setIsLoading(false);
    return;
  }
  if (selectedEventForBet.selectedOdd <= 0) {
    toast.error("Please select a Valid Odd");
    setIsLoading(false);
    return;
  }
  if (game == "cricket" || game == "tennis" || game == "soccer") {
    if (
      (selectedEventForBet.marketName == "MATCH_ODDS" &&
        gameSettings?.minStack > selectedAmount_) ||
      (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
        gameSettings?.minStack > selectedAmount_) ||
      (selectedEventForBet.marketName == "FANCY_ODDS" &&
        gameSettings?.sessionMinStack > selectedAmount_) ||
      (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
        gameSettings?.sessionMinStack > selectedAmount_)
    ) {
      toast.error(
        `Minimum bet amount is ${
          selectedEventForBet.marketName == "FANCY_ODDS_2"
            ? gameSettings?.sessionMinStack
            : selectedEventForBet.marketName == "FANCY_ODDS"
            ? gameSettings?.sessionMinStack
            : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
            ? gameSettings?.bookmakerMinStack
            : gameSettings?.minStack
        }`
      );
      setIsLoading(false);
      return;
    }
    if (
      (selectedEventForBet.marketName == "MATCH_ODDS" &&
        gameSettings?.maxStack < selectedAmount_) ||
      (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
        gameSettings?.bookmakerMaxStack < selectedAmount_) ||
      (selectedEventForBet.marketName == "FANCY_ODDS" &&
        gameSettings?.sessionMaxStack < selectedAmount_) ||
      (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
        gameSettings?.sessionMaxStack < selectedAmount_)
    ) {
      toast.error(
        `Maximum bet amount is ${
          selectedEventForBet.marketName == "FANCY_ODDS_2"
            ? gameSettings?.sessionMaxStack
            : selectedEventForBet.marketName == "FANCY_ODDS"
            ? gameSettings?.sessionMaxStack
            : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
            ? gameSettings?.bookmakerMaxStack
            : gameSettings?.maxStack
        }`
      );
      setIsLoading(false);
      return;
    }
  }
  if (selectedEventForBet?.isFancy) {
    delay = 1200;
  }
  let profit;
  let loss;
  if (game == "cricket" && selectedEventForBet.isBookmaker) {
    profit = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "tennis" || game == "soccer") {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else {
    profit = cricketMatchOddsPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketMatchOddsPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  }
  let payload = {
    amount: selectedAmount_,
    profit: parseFloat(profit).toFixed(2),
    loss: parseFloat(loss).toFixed(2),
    marketId: selectedEventForBet.marketId,
    selectionId: selectedEventForBet.selectedId,
    type: selectedEventForBet.type,
    eventId: parseInt(selectedEventForBet.eventId),
    runnerName: selectedEventForBet.runnerName,
    gameType:
      selectedEventForBet.marketName != "MATCH_ODDS" ||
      selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
      selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
        ? selectedEventForBet.gameType
        : selectedEventForBet.marketName,
    marketName: selectedEventForBet.marketName,
  };
  if (selectedEventForBet.isBookmaker && selectedEventForBet?.layRate) {
    payload["layRate"] = selectedEventForBet.selectedOdd;
  } else if (
    selectedEventForBet?.isBookmaker &&
    selectedEventForBet?.backRate
  ) {
    payload["backRate"] = selectedEventForBet.selectedOdd;
  }

  if (selectedEventForBet.type == "back") {
    payload["back"] = parseFloat(selectedEventForBet.selectedOdd);
    payload["backRate"] = parseFloat(selectedEventForBet.selectedOdd);
  } else {
    payload["layRate"] = parseFloat(selectedEventForBet.selectedOdd);
    payload["lay"] = parseFloat(selectedEventForBet.selectedOdd);
  }

  await new Promise((resolve) => setTimeout(resolve, delay));

  if (game == "cricket" && !selectedEventForBet.isFancy) {
    const { response, code, data } = await placeCricketBetAPI(
      payload,
      userData.token
    );
    if (code == 200) {
      toast.success("Bet Placed");
      setBetModalOpen(false);
    } else if (code == 500) {
      toast.error(response);
    } else if (code == "ERR_BAD_RESPONSE") {
      toast.error("Something Went Wrong");
    } else {
      toast.error("Something Went Wrong");
    }
  } else if (game == "cricket" && selectedEventForBet?.isFancy) {
    let fancyPayload = {
      amount: selectedAmount_,
      eventId: parseInt(selectedEventForBet.eventId),
      marketId: selectedEventForBet.marketId,
      marketName:
        selectedEventForBet.marketName != "MATCH_ODDS" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
          ? selectedEventForBet.gameType
          : selectedEventForBet.marketName,
      gameType: selectedEventForBet.gameType,
    };

    if (selectedEventForBet.type === "lay") {
      fancyPayload["layRate"] = selectedEventForBet.selectedRate;
      fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
      if (selectedEventForBet.gameType == "fancy1") {
        fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
        fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
      }
    } else {
      fancyPayload["backRate"] = selectedEventForBet.selectedRate;
      fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
      if (selectedEventForBet.gameType == "fancy1") {
        fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
        fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
      }
    }

    if (
      applyFancyFor.find(
        (item) =>
          item?.toLowerCase() == selectedEventForBet?.gameType?.toLowerCase()
      )
    ) {
      if (selectedEventForBet?.type == "lay") {
        if (selectedEventForBet?.gameType == "fancy1") {
          fancyPayload.profit = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          fancyPayload.loss = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
        } else {
          fancyPayload.profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          fancyPayload.loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
        }
      } else {
        if (selectedEventForBet?.gameType == "fancy1") {
          fancyPayload.profit = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          fancyPayload.loss = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
        } else {
          fancyPayload.profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          fancyPayload.loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
        }
      }
    } else {
      fancyPayload.profit = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      fancyPayload.loss = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
      fancyPayload.selectionId = selectedEventForBet.selectedId;
      fancyPayload.runnerName = selectedEventForBet.runnerName;
      if (selectedEventForBet.type === "lay") {
        fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
      } else {
        fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
      }
    }

    const { response, code, data } = await placeFancyCricketBetAPI(
      fancyPayload,
      userData.token
    );
    if (code == 200) {
      toast.success("Bet Placed successfully");
      setIsLoading(false);
      setTimerSeconds(0);
      setBetModalOpen(false);
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
    } else if (code == 500) {
      toast.error(response);
    } else if (code == "ERR_BAD_RESPONSE") {
      setIsLoading(false);
      toast.error("Something Went wrong");
    } else {
      toast.error("Something Went Wrong");
    }
  }
  setIsLoading(false);
  dispatch({
    type: "gameData/setSelectedEventForBet",
    payload: null,
  });
};
const naviagte = useNavigate();

const getBetCount = async () => {
  const { response } = await getBetCountAPI();
  return response;
};

useEffect(() => {
  let timerInterval;
  if (showTimer) {
    timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
  }
  return () => {
    clearInterval(timerInterval);
  };
}, [showTimer]);

useEffect(() => {
  if (timerSeconds === 0) {
    setBetModalOpen(false);
    setShowTimer(false);
    setTimerSeconds(5);
  }
}, [timerSeconds]);
const getLiveStreamingUrl = async () => {
  const { response } = await getLiveStreamingUrlAPI(gameEvent?.eventId);
  setChannelId(response);
};
useEffect(() => {
  getLiveStreamingUrl();
}, [gameEvent?.eventId]);
return (
  <>
    {loading ? (
      <>
        <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center loader">
          <img
            src="/assets/images/loader.png"
            alt="Loader"
            class="flip-vertical-right"
          />
        </div>
      </>
    ) : null}

    <div class="position-relative mt-3">
      <table
        className="table"
        style={{ marginBottom: "-4px", marginTop: "-5px" }}
      >
        <tbody className="lgaai">
          <tr>
            <th width="50%">
              <div style={{ display: "flex" }}>
                <div
                  className="toggle-tv-old lgaai"
                  onClick={() => setShowTv(!showTv)}
                  style={{ cursor: "pointer", width: "50%" }}
                >
                  <p className="active text-left text-light m-0" id="tvBtn">
                    Tv
                  </p>
                </div>
                <div
                  className="toggle-tv-old lgaai"
                  onClick={() => setShowScore(!showScore)}
                  style={{ cursor: "pointer", width: "50%" }}
                >
                  <p className="active text-left text-light m-0" id="tvBtn">
                    Score
                  </p>
                </div>
              </div>
            </th>
          </tr>
        </tbody>
      </table>

      {showTv && (
        <div
          className="dragOuter show-tv"
          id="dragOuter"
          // style={{ position: "fixed" }}
        >
          <div className="dragHeader">
            <div className="closeDrag" onClick={() => setShowTv(!showTv)}>
              <img className="" src="/images/close_icon.png" alt="" />
            </div>
          </div>
          <div className="TVwrapper">
            <iframe
              style={{
                padding: "0 10px",
                width: "100%",
              }}
              src={`https://video.proexch.in/tv/static?chid=${channelId}`}
            />
          </div>
        </div>
      )}

      {showScore && (
        <iframe
          style={{
            width: "100%",
            height: "102px",
          }}
          src={`https://www.7777exch.com/ScoreCardNew1.aspx?EventID=${
            gameEvent?.eventId
          }`}
        />
      )}

      {oddQuery?.data?.response &&
        oddQuery?.data?.response?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS
          ?.length > 0 && (
          <table class="match-bets-old table table-bordered">
            <thead class="lgaai">
              <tr>
                <th className="" width="46%">
                  Team
                  <img
                    src="/assets/images/info.png"
                    onClick={() => handleOpenInfoModal("Bookmaker")}
                    class="mtachImgInfo"
                    tilte="Rules (Bookmaker)"
                    alt="Rules (Bookmaker)"
                    id="BOOKMAKER"
                  />
                  <p class="m-0">
                    {/* Min : {gameSettings?.minStack} | Max :{" "} */}
                    {gameSettings?.maxStack}
                  </p>
                </th>
                <th
                  width="22%"
                  align="center"
                  valign="middle"
                  bgcolor="#d72012"
                  class="vertical-middle FontTextWhite10px"
                >
                  Lagai
                </th>
                <th
                  width="22%"
                  align="center"
                  valign="middle"
                  bgcolor="#d72012"
                  class="vertical-middle FontTextWhite10px"
                >
                  Khai
                </th>
              </tr>
            </thead>
            <tbody
              data-market-id={1585135}
              data-market-sid="Match Winner_1427389"
            >
              {oddQuery?.data?.response?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS.map(
                (match, index) => (
                  <>
                    <tr
                      style={{ background: "#b4c0ac" }}
                      data-runner-id={8910029}
                      data-runner-sid="Dubai Capitals  _1427389"
                      className={
                        match?.status == "ACTIVE"
                          ? "fav_run relative ht-50"
                          : "non_fav_run relative ht-50"
                      }
                    >
                      <th
                        data-runner-sid="Dubai  Capitals  _1427389"
                        data-column="runner_name"
                        className="mybets d-flex text-center font-17 justify-content-center align-items-center"
                      >
                        {match?.runnerName}
                        <b
                          className={
                            gameSettings?.plExposureResponseDTOs
                              ?.find(
                                (item) =>
                                  item.marketIdExternal ==
                                  oddQuery?.data?.response?.bookMakerOdds[0]
                                    ?.bm1?.marketId
                              )
                              ?.runnerPlExposureDTOS?.find(
                                (pl) => pl.selectionId == match?.selectionId
                              )?.exposure >= 0
                              ? "text-green mx-2"
                              : "text-red mx-2"
                          }
                        >
                          {" "}
                          {
                            gameSettings?.plExposureResponseDTOs
                              ?.find(
                                (item) =>
                                  item.marketIdExternal ==
                                  oddQuery?.data?.response?.bookMakerOdds[0]
                                    ?.bm1?.marketId
                              )
                              ?.runnerPlExposureDTOS?.find(
                                (pl) => pl.selectionId == match?.selectionId
                              )?.exposure
                          }
                        </b>
                      </th>
                      {match?.status == "SUSPENDED" ||
                      (match?.status === null && match?.status === null) ? (
                        <>
                          <th className="suspended">{match?.status}</th>
                        </>
                      ) : null}
                      <th
                        data-column="back"
                        onClick={() => {
                          bookmakerSetBet(match, "back1", match?.marketName);
                        }}
                        className="text-dark text-center font-17 teamClrBlue"
                      >
                        {/* <span> {parseFloat(match?.back1 / 100).toFixed(2)}</span> */}
                        <span> {match?.back1}</span>
                      </th>

                      <th
                        onClick={() => {
                          bookmakerSetBet(match, "lay1", match?.marketName);
                        }}
                        data-column="lay"
                        className="text-dark font-17 teamClrPink"
                      >
                        {/* <span> {parseFloat(match?.lay1 / 100).toFixed(2)}</span> */}
                        <span>{match?.lay1}</span>
                      </th>
                    </tr>
                    {match?.remark && (
                      <th className="odd-remark">{match?.remark}</th>
                    )}
                  </>
                )
              )}
            </tbody>
          </table>
        )}
      {oddQuery?.data?.response?.fancyOdds?.find(
        (item) => item?.gameType?.toLocaleLowerCase() == "fancy1"
      )?.oddDetailsDTOS?.length > 0 && (
        <table class="match-bets-old sessionodds table table-bordered">
          <thead class="lgaai">
            <tr style={{ backgroundColor: "#488df2ad" }}>
              <th width="46%">
                TOSS
                <img
                  onClick={() => handleOpenInfoModal("Session")}
                  src="/assets/images/info.png"
                  class="mtachImgInfo"
                  tilte="Rules (Bookmaker)"
                  alt="Rules (Bookmaker)"
                  id="BOOKMAKER"
                />
              </th>

              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#d72012"
                class="vertical-middle FontTextWhite10px"
              >
                YES
              </th>
            </tr>
          </thead>
          <tbody>
            {oddQuery?.data?.response?.fancyOdds
              ?.find((item) => item?.gameType?.toLocaleLowerCase() == "fancy1")
              ?.oddDetailsDTOS?.sort((a, b) => {
                if (!isNaN(a.runnerName) && !isNaN(b.runnerName)) {
                  return a.runnerName - b.runnerName;
                } else if (!isNaN(a.runnerName)) {
                  return -1;
                } else if (!isNaN(b.runnerName)) {
                  return 1;
                } else {
                  return a.runnerName.localeCompare(b.runnerName);
                }
              })
              ?.map((match) => (
                <>
                  <tr
                    data-runner-id="8811535"
                    data-runner-sid={match?.runnerName}
                    class="relative ht-50"
                    // style={{ background: "rgb(180, 192, 172)" }}
                  >
                    <th class="mybets vertical-middle d-flex justify-content-center align-items-center">
                      <div>
                        {match?.runnerName} :
                        {/* <b class="mx-2 text-primary">0</b> */}
                        <div class="mm-holder mm-divider">
                          {/* <span>Min: </span>
                        <span>{gameSettings?.sessionMinStack}</span>
                        &nbsp;&nbsp;
                        <span>Max: </span> */}
                          <span>{gameSettings?.sessionMaxStack}</span>
                        </div>
                      </div>
                    </th>
                    {match?.status == "SUSPENDED" ||
                    match?.status == "Ball Running" ||
                    match?.status === null ? (
                      <>
                        <th className="suspended" colSpan={2}>
                          {match?.status}
                        </th>
                      </>
                    ) : null}
                    {/* <th class="suspended">SUSPENDED</th> */}

                    <th
                      class="text-dark font-17 teamClrPink"
                      onClick={() =>
                        fancySetBet(match, "back1", "fancyOdds", "fancy1")
                      }
                    >
                      <span>
                        <span>{parseInt(match?.back1)}</span>
                        <br />
                        {0.9}
                      </span>
                    </th>
                  </tr>
                  {match?.remark && (
                    <th className="odd-remark">{match?.remark}</th>
                  )}
                </>
              ))}
          </tbody>
        </table>
      )}

      <div class="session-plus-minus mb-2 session-style">
        <p>
          SESSION P/M<span class="text-white">{fancyPlusMinus}/-</span>
        </p>
      </div>

      {/* SESSION */}
      <table class="match-bets-old sessionodds table table-bordered">
        <thead class="lgaai">
          <tr style={{ backgroundColor: "#488df2ad" }}>
            <th width="46%">
              Session
              <img
                onClick={() => handleOpenInfoModal("Session")}
                src="/assets/images/info.png"
                class="mtachImgInfo"
                tilte="Rules (Bookmaker)"
                alt="Rules (Bookmaker)"
                id="BOOKMAKER"
              />
            </th>
            <th
              width="22%"
              align="center"
              valign="middle"
              bgcolor="#d72012"
              class="vertical-middle FontTextWhite10px"
            >
              NO
            </th>
            <th
              width="22%"
              align="center"
              valign="middle"
              bgcolor="#d72012"
              class="vertical-middle FontTextWhite10px"
            >
              Yes
            </th>
          </tr>
        </thead>
        <tbody>
          {numericOdds?.map((match) => (
            <>
              <tr
                data-runner-id="8811535"
                data-runner-sid={match?.runnerName}
                class="relative ht-50"
                // style={{ background: "rgb(180, 192, 172)" }}
              >
                <th class="mybets vertical-middle d-flex justify-content-center align-items-center">
                  <div>
                    {match?.runnerName} :
                    {/* <b class="mx-2 text-primary">0</b> */}
                    <div class="mm-holder mm-divider">
                      {/* <span>Min: </span>
                        <span>{gameSettings?.sessionMinStack}</span>
                        &nbsp;&nbsp;
                        <span>Max: </span> */}
                      <span>{match?.maxLimit}</span>
                    </div>
                  </div>
                </th>
                {match?.status == "SUSPENDED" ||
                match?.status == "Ball Running" ||
                match?.status === null ? (
                  <>
                    <th className="suspended" colSpan={2}>
                      {match?.status}
                    </th>
                  </>
                ) : null}
                {/* <th class="suspended">SUSPENDED</th> */}
                <th
                  class="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.lay1)}</span>
                    <br /> {parseInt(match?.laySize1)}
                  </span>
                </th>

                <th
                  class="text-dark font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.back1)}</span>
                    <br />
                    {parseInt(match?.backSize1)}
                  </span>
                </th>
              </tr>
              {match?.remark && <th className="odd-remark">{match?.remark}</th>}
            </>
          ))}
          {fallOfWicketsOdds?.map((match) => (
            <>
              <tr
                data-runner-id="8811535"
                data-runner-sid={match?.runnerName}
                class="relative ht-50"
                // style={{ background: "rgb(180, 192, 172)" }}
              >
                <th class="mybets vertical-middle d-flex justify-content-center align-items-center">
                  <div>
                    {match?.runnerName} :
                    {/* <b class="mx-2 text-primary">0</b> */}
                    <div class="mm-holder mm-divider">
                      {/* <span>Min: </span>
                        <span>{gameSettings?.sessionMinStack}</span>
                        &nbsp;&nbsp;
                        <span>Max: </span> */}
                      <span>{match?.maxLimit}</span>
                    </div>
                  </div>
                </th>
                {match?.status == "SUSPENDED" ||
                match?.status == "Ball Running" ||
                match?.status === null ? (
                  <>
                    <th className="suspended" colSpan={2}>
                      {match?.status}
                    </th>
                  </>
                ) : null}
                {/* <th class="suspended">SUSPENDED</th> */}
                <th
                  class="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.lay1)}</span>
                    <br /> {parseInt(match?.laySize1)}
                  </span>
                </th>

                <th
                  class="text-dark font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.back1)}</span>
                    <br />
                    {parseInt(match?.backSize1)}
                  </span>
                </th>
              </tr>
              {match?.remark && <th className="odd-remark">{match?.remark}</th>}
            </>
          ))}
          {firstWicketOdds?.map((match) => (
            <>
              <tr
                data-runner-id="8811535"
                data-runner-sid={match?.runnerName}
                class="relative ht-50"
                // style={{ background: "rgb(180, 192, 172)" }}
              >
                <th class="mybets vertical-middle d-flex justify-content-center align-items-center">
                  <div>
                    {match?.runnerName} :
                    {/* <b class="mx-2 text-primary">0</b> */}
                    <div class="mm-holder mm-divider">
                      {/* <span>Min: </span>
                        <span>{gameSettings?.sessionMinStack}</span>
                        &nbsp;&nbsp;
                        <span>Max: </span> */}
                      <span>{match?.maxLimit}</span>
                    </div>
                  </div>
                </th>
                {match?.status == "SUSPENDED" ||
                match?.status == "Ball Running" ||
                match?.status === null ? (
                  <>
                    <th className="suspended" colSpan={2}>
                      {match?.status}
                    </th>
                  </>
                ) : null}
                {/* <th class="suspended">SUSPENDED</th> */}
                <th
                  class="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.lay1)}</span>
                    <br /> {parseInt(match?.laySize1)}
                  </span>
                </th>

                <th
                  class="text-dark font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.back1)}</span>
                    <br />
                    {parseInt(match?.backSize1)}
                  </span>
                </th>
              </tr>
              {match?.remark && <th className="odd-remark">{match?.remark}</th>}
            </>
          ))}
          {otherOdds?.map((match) => (
            <>
              <tr
                data-runner-id="8811535"
                data-runner-sid={match?.runnerName}
                class="relative ht-50"
                // style={{ background: "rgb(180, 192, 172)" }}
              >
                <th class="mybets vertical-middle d-flex justify-content-center align-items-center">
                  <div>
                    {match?.runnerName} :
                    {/* <b class="mx-2 text-primary">0</b> */}
                    <div class="mm-holder mm-divider">
                      {/* <span>Min: </span>
                        <span>{gameSettings?.sessionMinStack}</span>
                        &nbsp;&nbsp;
                        <span>Max: </span> */}
                      <span>{match?.maxLimit}</span>
                    </div>
                  </div>
                </th>
                {match?.status == "SUSPENDED" ||
                match?.status == "Ball Running" ||
                match?.status === null ? (
                  <>
                    <th className="suspended" colSpan={2}>
                      {match?.status}
                    </th>
                  </>
                ) : null}
                {/* <th class="suspended">SUSPENDED</th> */}
                <th
                  class="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.lay1)}</span>
                    <br /> {parseInt(match?.laySize1)}
                  </span>
                </th>

                <th
                  class="text-dark font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.back1)}</span>
                    <br />
                    {parseInt(match?.backSize1)}
                  </span>
                </th>
              </tr>
              {match?.remark && <th className="odd-remark">{match?.remark}</th>}
            </>
          ))}
          {boundariesOdds?.map((match) => (
            <>
              <tr
                data-runner-id="8811535"
                data-runner-sid={match?.runnerName}
                class="relative ht-50"
                // style={{ background: "rgb(180, 192, 172)" }}
              >
                <th class="mybets vertical-middle d-flex justify-content-center align-items-center">
                  <div>
                    {match?.runnerName} :
                    {/* <b class="mx-2 text-primary">0</b> */}
                    <div class="mm-holder mm-divider">
                      {/* <span>Min: </span>
                        <span>{gameSettings?.sessionMinStack}</span>
                        &nbsp;&nbsp;
                        <span>Max: </span> */}
                      <span>{match?.maxLimit}</span>
                    </div>
                  </div>
                </th>
                {match?.status == "SUSPENDED" ||
                match?.status == "Ball Running" ||
                match?.status === null ? (
                  <>
                    <th className="suspended" colSpan={2}>
                      {match?.status}
                    </th>
                  </>
                ) : null}
                {/* <th class="suspended">SUSPENDED</th> */}
                <th
                  class="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.lay1)}</span>
                    <br /> {parseInt(match?.laySize1)}
                  </span>
                </th>

                <th
                  class="text-dark font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span>
                    <span>{parseInt(match?.back1)}</span>
                    <br />
                    {parseInt(match?.backSize1)}
                  </span>
                </th>
              </tr>
              {match?.remark && <th className="odd-remark">{match?.remark}</th>}
            </>
          ))}
        </tbody>
      </table>

      {/* Match Bets */}
      {userBets &&
        userBets?.length > 0 &&
        userBets?.filter((item) => item?.marketName == "BOOKMAKER_ODDS_1")
          .length > 0 && (
          <table
            width="100%"
            border="0"
            cellPadding="2"
            cellspacing="2"
            class="old-match-bets sesstion-bets text-center"
            style={{ marginTop: "-15px", color: "black" }}
          >
            <thead>
              <tr class="text-white table-bordered">
                <th
                  height="25"
                  align="center"
                  valign="middle"
                  class="FontTextWhite10px"
                >
                  Sr.
                </th>
                <th align="right" valign="middle" class="FontTextWhite10px">
                  {" "}
                  Rate
                </th>
                <th align="right" valign="middle" class="FontTextWhite10px">
                  Amount
                </th>
                <th align="center" valign="middle" class="FontTextWhite10px">
                  {" "}
                  Mode
                </th>
                <th align="left" valign="middle" class="FontTextWhite10px">
                  Team
                </th>
              </tr>
            </thead>
            <tbody>
              {userBets &&
                userBets
                  ?.filter((item) => item?.marketName == "BOOKMAKER_ODDS_1")
                  ?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td> {item?.lay ? item?.lay : item.back}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.back ? "LAGAI" : "KHAI"}</td>
                      <td>{item?.runnerName} </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        )}

      {/* Session Bets */}
      {userBets &&
        userBets?.length > 0 &&
        userBets?.filter((item) => item?.marketName != "BOOKMAKER_ODDS_1")
          .length > 0 && (
          <div class="table-responsive">
            <table
              width="100%"
              class="old-match-bets sesstion-bets"
              border="0"
              cellspacing="2"
              cellPadding="2"
              style={{
                color: "black",
              }}
            >
              <thead>
                <tr class="text-white">
                  <th
                    height="25"
                    align="center"
                    bgcolor="#b4c0ac"
                    class="FontTextWhite10px"
                  >
                    Sr.
                  </th>
                  <th align="left" bgcolor="#b4c0ac" class="FontTextWhite10px">
                    Session
                  </th>
                  <th align="right" bgcolor="#b4c0ac" class="FontTextWhite10px">
                    Run
                  </th>
                  <th
                    align="right"
                    valign="middle"
                    bgcolor="#b4c0ac"
                    class="FontTextWhite10px"
                  >
                    Rate
                  </th>
                  <th
                    align="right"
                    valign="middle"
                    bgcolor="#b4c0ac"
                    class="FontTextWhite10px"
                  >
                    Amount
                  </th>
                  <th
                    align="center"
                    bgcolor="#b4c0ac"
                    class="FontTextWhite10px"
                  >
                    Mode
                  </th>
                  <th
                    align="center"
                    bgcolor="#b4c0ac"
                    class="FontTextWhite10px"
                  >
                    DEC
                  </th>
                </tr>
              </thead>
              <tbody>
                {userBets &&
                  userBets
                    ?.filter((item) => item?.marketName != "BOOKMAKER_ODDS_1")
                    ?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.runnerName}</td>
                        <td>{item?.lay ? item?.lay : item.back}</td>
                        <td>
                          {" "}
                          {item?.backRate ? item?.backRate : item?.layRate}
                        </td>
                        <td>{item?.amount}</td>
                        <td> {item?.back ? "YES" : "NO"}</td>
                        <td>{item?.result}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
      {cricketData
        ?.filter((item) => item?.inPlay)
        ?.map((gameEvent) => (
          <div className={`old-matches-list  live-match`}>
            <div className="list-matches">
              <div onClick={() => navigateToGame(gameEvent)}>
                <h6>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                    className="flex"
                  >
                   
                      <i class="far fa-cricket fa-fw blink" />
                  
                    <span className="d-inline-flex align-items-center float-left mx-2">
                      <div className="blink" />
                    </span>
                    <span
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      {gameEvent.eventName}
                    </span>
                    <div
                      style={{
                        marginLeft: 10,
                        position: "absolute",
                        right: 0,
                      }}
                      className="ant-col gx-bg-flex gx-px-2 gx-align-items-center gx-justify-content-center ant-col-lg-4 ant-col-xl-5"
                    >
                      <span className="gx-mx-1 gx-text-primary gx-fs-lg">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 256 256"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M216,60H157l27.52-27.52a12,12,0,0,0-17-17L128,55,88.49,15.51a12,12,0,0,0-17,17L99,60H40A20,20,0,0,0,20,80V200a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V80A20,20,0,0,0,216,60Zm-4,136H44V84H212Z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </h6>
                <h5>
                  {moment(
                    gameEvent.eventTime
                      ? gameEvent.eventTime
                      : gameEvent.eventDate
                  ).format("DD MMM hh:mm A")}
                </h5>
                <div className="match-info" />
              </div>
            </div>
          </div>
        ))}
      <Link
        className="btn btn-aprimary w-100 clr-silver backbutton"
        to="/dashboard"
      >
        Back to Main Menu
      </Link>
    </div>

    {/* betting modal */}
    <Modal
      show={BetModalopen}
      centered
      className="bet-modal"
      onClose={handleBetModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="">
        <div className="modal-content">
          <a className="close-modal" onClick={handleBetModalClose}>
            Cancel
          </a>
          <div className="modal-body">
            <div className="container">
              <div className="bet-strip row">
                <div className="col-6">
                  <label>Team</label>
                  <p>{selectedEventForBet?.runnerName}</p>
                </div>
                <div className="col-3">
                  <label>
                    {selectedEventForBet?.isFancy ? "RUN" : "RATE"} :{" "}
                  </label>
                  <p> {selectedEventForBet?.selectedOdd}</p>
                </div>
                <div className="col-3">
                  <label>Mode</label>
                  <p>
                    {selectedEventForBet?.isFancy
                      ? selectedEventForBet.type == "back"
                        ? "YES"
                        : "NO"
                      : selectedEventForBet?.type != "back"
                      ? "Khai"
                      : "Lagai"}
                  </p>
                </div>
              </div>
              <div className="my-3 row">
                <div className="bet-price-btns col-lg-12 col-md-12 col-sm-12 col-12">
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("100")}
                  >
                    100
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("500")}
                  >
                    500
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("1000")}
                  >
                    1000
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("2000")}
                  >
                    2000
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("5000")}
                  >
                    5000
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("10000")}
                  >
                    10000
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("20000")}
                  >
                    20000
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("50000")}
                  >
                    50000
                  </button>
                  <button
                    type="button"
                    className="bet-price-btn btn btn-primary"
                    onClick={() => handleDefaultValueClick("100000")}
                  >
                    100000
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3 input-group">
                    <input
                      className="form-control"
                      autoComplete="off"
                      style={{
                        textDecoration: "none",
                        lineHeight: "normal",
                      }}
                      type="text"
                      name=""
                      inputmode="numeric"
                      value={selectedEventForBet?.amount}
                      onChange={(e) =>
                        dispatch({
                          type: "gameData/setSelectedEventForBet",
                          payload: {
                            ...selectedEventForBet,
                            amount: e.target.value,
                            profit: getProfit(
                              e.target.value,
                              game,
                              selectedEventForBet
                            ).profit,
                            loss: getProfit(
                              e.target.value,
                              game,
                              selectedEventForBet
                            ).loss,
                          },
                        })
                      }
                    />
                    <span
                      className="bg-danger text-white input-group-text"
                      id="basic-addon3"
                    >
                      <p>
                        {showTimer && (
                          <>
                            <p>{timerSeconds}</p>
                          </>
                        )}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-0 modal-footer">
            <button
              type="button"
              className="w-100 m-0 btn btn-primary"
              onClick={placeBet}
            >
              Placebet
            </button>
          </div>
        </div>
      </div>
    </Modal>

    {/* Modal */}
    <Modal
      show={open}
      centered
      onClose={handleCloseInfoModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">
              <h2 style={{ fontWeight: "bold", fontSize: "25px" }}>
                {" "}
                RULES
                <span style={{ color: "red" }}>({titleInfo}) </span>
              </h2>
            </div>
            <button
              type="button"
              onClick={handleCloseInfoModal}
              className="btn-close"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div
              className="matchRules"
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >
              <div className="matchRules">
                <h5> CRICKET </h5>
                <p> 1.मैच के टाई होने पर सभी सौदे मान्य किए जायेगे,</p>
                <p>
                  {" "}
                  2. सभी एडवांस फैंसी टॉस के कुछ देर पहले ससपेंड कर दी जाएगी ,{" "}
                </p>
                <p>
                  {" "}
                  3. यदि किसी तकनीकी खराबी के कारन कोई सेशन या फैंसी ID से हट
                  जाती है और दुबारा ID पर नहीं आती है तो उस सेशन पर किए गए सभी
                  सौदे मान्य किए जायेगे,{" "}
                </p>
                <p>
                  4. यदि किसी सेशन या फैंसी के रेट गलत चल जाते है तो गलत रेट पर
                  किए गए सौदे हटा दिए जायेगे,
                </p>
                <p>
                  5. स्कोर बोर्ड और टीवी थर्ड पार्टी सोर्स है अगर स्कोर स्लो और
                  गलत चलता है तो इस मे कम्पनी की कोई ज़िम्मेदारी नहीं है कृपया
                  सौदा अपने हिसाब से करे,
                </p>
                <p className="redP">
                  6. यदि कोई क्लाइंट ग्राउंड कमेंर्टी से एक ही IP से BOOT से VPN
                  से एक है टाइम मे एक से सौदे करता है तो करता है तो केवल जीत
                  वाले सौदे हटा दिए जाएंगे , उदहारण:-(
                  <span className="redSpan">
                    6 ओवर सेशन मै 44 YES और 48 NOT करता है और रन 43 बनते है तो
                    48 वाले सभी सौदे हटा दिए जा सकते है,
                  </span>
                  )
                </p>
                <p>
                  7. पेनल्टी के रन बर्तमान मे चल रहे किसी भी सेशन पर मान्य नहीं
                  किए जाते है,
                </p>
                <p>
                  8. यदि बर्तमान मे सेशन चल रहे हो और टीम (DLS) या किसी भी कारन
                  से जीत जाती है तो ओवर वाले सेशन जो कम्पलीट नहीं हुए है वो रद्द
                  कर दिया जायेगा और एक्स्ट्रा सेशन (
                  <span className="redSpan">
                    विकेट पार्टनरशिप ,प्लेयर के रन,एक्सटरा फैंसी) सभी पास किआ
                    जायेगा,
                  </span>
                </p>
                <p>
                  9.प्लेयर के 35 रन पर रिटायर्ड हर्ट या इंजर्ड होने पर प्लेयर के
                  रन डिक्लेअर 35 ही किये जायेंगे ,
                </p>
                <p>
                  {" "}
                  10. एडवांस सेशन और फैंसी केवल 1ST इनिंग के लिए ही VALID होती
                  है,{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
);
}
